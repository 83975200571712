import React from "react"
import styled from "styled-components";

const FlexDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .headdings {
    position: relative;
    padding: 0.25em;
    z-index: 1;
    left: 1em;
    top: 1em;
    h1 {
      color: white;
    background: #484848;
      font-size: 5em;
      font-weight: 500;
    }
    }

    @media (max-width: 768px) {
    flex-direction: column;
    overflow: hidden;
    .headdings {
    left: 0;
    width: 100%;
    top: 0;
    h1{
      font-size:2em;
    }
    }
    
    }
`

export default function LayoutFlex({ children }) {
  return (
    <FlexDiv>
      {children}
    </FlexDiv>
  )
}