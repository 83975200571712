import React from "react"
import styled from "styled-components";



const Caption = styled.div`
max-width: 960px;
margin: 0 auto;
border-bottom: 3px solid #ededed;
border-top: 1px solid #ededed;
display: flex;
justify-content: center;
flex-wrap: wrap;
align-items:center;
padding: 1em;
font-size: 1.5rem;
figcaption {
  font-size: 0.75em;
}

`
export default function Figure({ children }) {
  return (
    <Caption>
      {children}
    </Caption>
  )
}