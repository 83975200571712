import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "./Container"
import LayoutFlex from "../components/LayoutFLex"
import Figure from "../components/Figure"

export default function Main() {
  return (
    <div>
    <LayoutFlex style={{
            padding: "2em",
            width: "50%",
          }}>
        <div className="headdings">
          <h1>Lebensfluss</h1>
          <h2>SILVIJA PODGORAC</h2>
          <h3>KUNSTTHERAPIE, LEBENSBERATUNG</h3>
        </div>
        <StaticImage src="../images/main-background.jpg"
         alt="Main background"
         placeholder="blurred"
         layout="fixed"
         width={800}
         height={400}
        />
      </LayoutFlex>
      <Figure>
        <blockquote>
        <cite>„Wenn Worte fehlen, sprechen Bilder.“</cite>
       </blockquote>
        <figcaption>Schottenloher Gertraud</figcaption>
      </Figure>
      <Container >
      <p>F&uuml;r viele Menschen ist es schwer Worte zu finden, wenn sie im Leben vor schwierigen Lebenssituationen und Herausforderungen stehen.

Als Kunsttherapeutin und Lebensberaterin begleite ich Sie auf ihrem Weg zu mehr Klarheit, Mut und Zuversicht.<br></br>

Kreative F&auml;higkeiten sind wichtig, um im Lebensfluss zu bleiben.

Wenn der Lebensstrom ins Stocken ger&auml;t, wirkt der kreative Prozess l&ouml;send.</p>

<h3>Das Leben bewusst gestalten</h3>

<p>Ob mit Materialien wie Farbe, Papier, Ton, mit Bewegung und Worten - immer entsteht etwas Neues. Ein Werk, in welchem sich auch Unsagbares ausdr&uuml;cken kann.
<br></br>
Sie haben die M&ouml;glichkeit einen neuen Zugang zu sich Selbst zu finden, sowie auch die Kreativit&auml;t selbst als gro&szlig;e Kraftquelle zu erfahren. </p>
    </Container>
    <Figure>
        <blockquote>
        <cite>„Der Weg ist das Ziel“</cite>
       </blockquote>
        <figcaption>Konfuzius</figcaption>
      </Figure>
  </div>
  )
}