import * as React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Main from "../components/Main"
import "@fontsource/open-sans/300.css"
import "@fontsource/open-sans-condensed"
import SeO from "../components/SeO"

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "Open Sans Condensed",
}

// markup
const IndexPage = () => {
  return (    
    <main style={pageStyles}>
      <SeO title="Home" />
      <Header/>
      <Main/>
      <Footer/>
    </main>
  )
}

export default IndexPage
